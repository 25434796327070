<template>
  <EditorTemplate></EditorTemplate>
</template>

<script>
import EditorTemplate from "~/components/sections/EditorTemplate.vue";

export default {
  name: "EditorCreate",
  metaInfo: {
    title: "Editor",
  },
  data() {
    return {};
  },
  components: {
    EditorTemplate,
  },
};
</script>

<style lang="scss" scoped></style>
